import React, { useEffect, useState } from 'react';
import css from './CategoryFilter.module.css';
import FilterPopup from '../FilterPopup/FilterPopup';
import classNames from 'classnames';
import { useConfiguration } from '../../../context/configurationContext';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { parse } from '../../../util/urlHelpers';
import { SCHEMA_TYPE_MULTI_ENUM } from '../../../util/types';
import FilterPlain from '../FilterPlain/FilterPlain';

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOptions, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOptions && selectedOptions.length > 0;
  const mode = schemaType === SCHEMA_TYPE_MULTI_ENUM && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOptions.join(',')}` : null;
  return { [queryParamName]: value };
};

function CategoryFilter(props) {
  const {
    rootClassName,
    className,
    id,
    name,
    label,
    initialValues,
    queryParamNames,
    contentPlacementOffset,
    onSubmit,
    location,
    schemaType,
    searchMode,
    intl,
    showAsPopup,
    ...rest
  } = props;

  const { pub_category, pub_subCategory } = parse(location.search);
  const subCategories = pub_subCategory?.split(':')?.[1]?.split(',') || [];
  const [selectedRootCategory, setSelectedRootCategory] = useState(pub_category);
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    subCategories.reduce((obj, curr) => {
      return { ...obj, [curr]: true };
    }, '')
  );
  useEffect(() => {
    setSelectedRootCategory(pub_category);
  }, [pub_category]);
  const listingConfig = useConfiguration();

  //   useEffect(() => {
  //     const subTypeRawDataArray = pub_typeOfAnimal.split(',');
  //     const initialSubDataValue = {};
  //     subTypeRawDataArray.forEach(data => {
  //       console.log(54, data, pub_typeOfAnimal);
  //       data && Object.assign(initialSubDataValue, { [data]: true });
  //     });
  //     setSelectedAnimalSubType(initialSubDataValue);
  //     console.log(initialSubDataValue, pub_typeOfAnimal, subTypeRawDataArray);
  //   }, [pub_typeOfAnimal]);

  const { actingAnimalsCategory } = listingConfig.listing;

  const classes = classNames(rootClassName || css.root, className);

  const queryParamName = getQueryParamName(queryParamNames);

  const urlParam = queryParamName;

  const hasInitialValues =
    !!initialValues && !!initialValues[urlParam] && initialValues[urlParam].length > 0;
  const labelForPopup = hasInitialValues
    ? intl.formatMessage(
        { id: 'categoryFilter.labelSelected' },
        { labelText: initialValues[urlParam] }
      )
    : label;

  const namedInitialValues = { [name]: initialValues[urlParam] };

  const handleAnimalCategoryChange = rootId => {
    selectedRootCategory === rootId ? setSelectedRootCategory({}) : setSelectedRootCategory(rootId);
    setSelectedSubCategory({});
  };

  const handleAnimalSubCategoryChange = (e, callSubmit = false) => {
    const newArray = { ...selectedSubCategory, [e.target.name]: e.target.checked };
    setSelectedSubCategory(newArray);
    if (!callSubmit) return;
    const queryParams = Object.keys(newArray).filter(key => newArray[key] === true);
    onSubmit({
      pub_category,
      pub_subCategory: queryParams.length ? `has_any:${queryParams.join(',')}` : null,
    });
  };

  const renderSuboptions = (rootId, callSubmit) => {
    // if (selectedAnimalType !== rootId || rootId === 'any') {
    //   return null;
    // }
    const subCat = actingAnimalsCategory.find(({ value }) => value === rootId).subcategories || [];
    return (
      <>
        {subCat.map(({ label, value }) => (
          <div className={css.folder} key={value}>
            <label className={classNames(selectedRootCategory !== rootId && css.labelDisabled)}>
              <input
                type="checkbox"
                name={value}
                checked={selectedSubCategory[value] || false}
                disabled={selectedRootCategory !== rootId}
                // onChange={e => console.log(e.target.value, e.target.checked, e.target.name)}
                onChange={e => {
                  handleAnimalSubCategoryChange(e, callSubmit);
                }}
              />
              {selectedSubCategory[value] === true ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 25 26"
                  fill="none"
                >
                  <path
                    d="M24 23.2222V2.77778C24 2.07208 23.4279 1.5 22.7222 1.5L2.27778 1.5C1.57208 1.5 1 2.07208 1 2.77778V23.2222C1 23.9279 1.57208 24.5 2.27778 24.5H22.7222C23.4279 24.5 24 23.9279 24 23.2222Z"
                    fill="#222325"
                    stroke="#222325"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5.68555 13.8523L9.9448 18.1116L19.3151 8.74121"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                >
                  <path
                    className={css.box}
                    d="M18.3574 17.0556V1.94444C18.3574 1.42284 17.9257 1 17.3932 1L1.96482 1C1.43227 1 1.00055 1.42284 1.00055 1.94444V17.0556C1.00055 17.5772 1.43227 18 1.96482 18H17.3932C17.9257 18 18.3574 17.5772 18.3574 17.0556Z"
                    stroke="#B5B5B5"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}

              {label}
            </label>
          </div>
        ))}
      </>
    );
  };

  const handleClear = () => {
    const { currentSearchParams, history } = props;
    const searchParams = {
      ...currentSearchParams,
    };
    setSelectedRootCategory(null);
    setSelectedSubCategory({});
    // onSubmit(format({}, queryParamName, schemaType, searchMode));
    onSubmit({
      pub_category: null,
      pub_subCategory: null,
    });
  };

  const handleSubmit = values => {
    const subCategories = Object.keys(selectedSubCategory).filter(
      key => selectedSubCategory[key] === true
    );

    // const usedValue = subCategories.length ? subCategories : [selectedRootCategory];
    const usedValue = {
      pub_category: selectedRootCategory,
      pub_subCategory: subCategories.length ? `has_any:${subCategories.join(',')}` : null,
    };
    // onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    onSubmit(usedValue);
  };

  return showAsPopup ? (
    <FilterPopup
      className={classes}
      rootClassName={rootClassName}
      popupClassName={css.popupSize}
      name={name}
      label={labelForPopup}
      isSelected={hasInitialValues}
      id={`${id}.popup`}
      showAsPopup
      labelMaxWidth={250}
      contentPlacementOffset={contentPlacementOffset}
      onSubmit={handleSubmit}
      initialValues={namedInitialValues}
      keepDirtyOnReinitialize
      onClear={handleClear}
      customClear={true}
      {...rest}
    >
      <div className={css.popupOpts}>
        {actingAnimalsCategory.map(({ label, value }) => (
          <div key={value} className={css.radioContainer}>
            <label>
              <input
                type="radio"
                name="animalType"
                onChange={() => handleAnimalCategoryChange(value)}
              />
              <div className={css.customRadio}>
                {value === selectedRootCategory ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <circle cx="13" cy="13" r="12.4" stroke="black" strokeWidth="1.2" />
                    <circle cx="13" cy="13" r="6" fill="black" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <circle cx="13" cy="13" r="12.4" stroke="#B5B5B5" strokeWidth="1.2" />
                  </svg>
                )}
              </div>
              {label}
            </label>

            {renderSuboptions(value)}
          </div>
        ))}
      </div>
    </FilterPopup>
  ) : (
    <FilterPlain
      className={className}
      rootClassName={rootClassName}
      label={label}
      // labelSelection={labelForPopup}
      isSelected={hasInitialValues}
      id={`${id}.plain`}
      liveEdit
      // contentPlacementOffset={contentStyle}
      onSubmit={handleSubmit}
      customClear={true}
      onClear={handleClear}
      initialValues={namedInitialValues}
      {...rest}
    >
      <div className={css.options}>
        {actingAnimalsCategory.map(({ label, value }) => (
          <div key={value} className={css.radioContainer}>
            <label>
              <input
                type="radio"
                name="animalType"
                checked={value === selectedRootCategory}
                onChange={() => {
                  handleAnimalCategoryChange(value);
                  onSubmit({ pub_category: value, pub_subCategory: null });
                }}
              />
              <div className={css.customRadio}>
                {value === selectedRootCategory ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <circle cx="13" cy="13" r="12.4" stroke="black" strokeWidth="1.2" />
                    <circle cx="13" cy="13" r="6" fill="black" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                  >
                    <circle cx="13" cy="13" r="12.4" stroke="#B5B5B5" strokeWidth="1.2" />
                  </svg>
                )}
              </div>
              {label}
            </label>

            {renderSuboptions(value, true)}
          </div>
        ))}
      </div>
    </FilterPlain>
  );
}

export default compose(
  withRouter,
  injectIntl
)(CategoryFilter);
